<template>
  <v-container class="down-top-padding" fluid>
    <v-row>
      <v-col cols="12">
        <v-card text>
          <v-skeleton-loader
            v-if="!applicationFetch"
            class="ma-5"
            type="article, actions"
            v-bind="attrs"
          ></v-skeleton-loader>
          <v-container v-else>
            <v-row>
              <v-col cols="12" lg="12" pa-0>
                <div class="text-center">
                  <h1
                    v-if="application.data.name"
                    class="mb-0 mt-4 text-h5"
                  >{{ application.data.name }}</h1>
                  <v-chip
                    v-if="application.data.status !== 'DRAFT'"
                    :color="getStatusColor(application.data.status)"
                    class="ma-2"
                    outlined
                    small
                  >
                    {{
                      getStatusName(application.data.status)
                    }}

                  </v-chip>
                </div>
              </v-col>
              <v-col v-if="application.data.is_offline">
                <v-btn
                  :disabled="false"
                  class="ma-4 mr-1"
                  large
                  outlined
                  color="primary"
                  :to="`/answerkey/${this.$route.params.id}/${this.$route.params.application}`"
                >Inserir gabarito</v-btn>
                <v-tooltip
                  v-model="show"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="grey lighten-1">
                        mdi-information-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Disponível apenas após a data final da avaliação</span>
                </v-tooltip>
                <v-btn
                  v-if="application && application.data.printable_file"
                  :href="application.data.printable_file"
                  class="ma-4"
                  color="primary"
                  large
                  outlined
                  target="_blank"
                >
                  <v-icon class="ml-2">mdi-download</v-icon>
                  Baixar PDF da avaliação
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex justify-end">
                <v-btn
                  :href="report_url"
                  class="ma-4"
                  color="primary"
                  large
                  outlined
                >
                  Visualizar Relatório de Notas
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="fetchCount">
      <v-col cols="12" md="12">
        <v-card class="mx-auto">
          <v-card-title style="color: #455a64">
            Visão geral
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="pt-0" cols="12" md="4">
                <div class="d-flex flex-column align-center">
                  <div class="body-1 pb-1 text-break">Percentual de provas não iniciadas</div>
                  <div class="body-2 pb-2 text-break">
                    {{ 'Seus alunos: ' + statusCount.NOT_STARTED + ' / ' + statusCount.total }}
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :value="(statusCount.NOT_STARTED / statusCount.total) * 100"
                    :width="10"
                    color="info"
                  >
                    {{ ((statusCount.NOT_STARTED / statusCount.total) * 100).toFixed(2) + '%' }}
                  </v-progress-circular>
                </div>
              </v-col>
              <v-col class="pt-0" cols="12" md="4">
                <div class="d-flex flex-column align-center">
                  <div class="body-1 pb-1 text-break">Percentual de provas em andamento</div>
                  <div class="body-2 pb-2 text-break">
                    {{ 'Seus alunos: ' + statusCount.STARTED + ' / ' + statusCount.total }}
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :value="(statusCount.STARTED / statusCount.total) * 100"
                    :width="10"
                    color="teal"
                  >
                    {{ ((statusCount.STARTED / statusCount.total) * 100).toFixed(2) + '%' }}
                  </v-progress-circular>
                </div>
              </v-col>
              <v-col class="pt-0" cols="12" md="4">
                <div class="d-flex flex-column align-center">
                  <div class="body-1 pb-1 text-break">Percentual de provas finalizadas</div>
                  <div class="body-2 pb-2 text-break">{{
                      'Seus alunos: ' + (statusCount.ENDED + statusCount.ANSWERS_FROM_BUBBLE_SHEET) + ' / ' + statusCount.total
                    }}
                  </div>
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :value="( (statusCount.ENDED + statusCount.ANSWERS_FROM_BUBBLE_SHEET) / statusCount.total) * 100"
                    :width="10"
                    color="green"
                  >
                    {{
                      (((statusCount.ENDED + statusCount.ANSWERS_FROM_BUBBLE_SHEET) / statusCount.total) * 100).toFixed(2) + '%'
                    }}
                  </v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="!applicationFetch">
      <v-row>
        <v-col class="mt-3" cols="12" md="6">
          <v-card class="mx-auto" outlined>
            <v-skeleton-loader type="table-heading" v-bind="attrs"></v-skeleton-loader>
            <v-skeleton-loader type="table-heading" v-bind="attrs"></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col class="mt-3" cols="12" md="6">
          <v-card class="mx-auto" outlined>
            <v-skeleton-loader type="table-heading" v-bind="attrs"></v-skeleton-loader>
            <v-skeleton-loader type="table-heading" v-bind="attrs"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row>
        <v-col class="mt-3" cols="12" md="6">
          <v-card class="mx-auto" outlined>
            <v-list disabled>
              <v-subheader>Resumo</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item v-if="application.data.questions_random_order">
                  <v-list-item-icon>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Questões em ordem aleatória</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="application.data.questions_random_order"></v-divider>

                <v-list-item v-if="application.data.wordings_random_order">
                  <v-list-item-icon>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Enunciados em ordem aleatória</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="application.data.wordings_random_order"></v-divider>

                <v-list-item v-if="application.data.answers_random_order">
                  <v-list-item-icon>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Alternativas em ordem aleatória</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="application.data.answers_random_order"></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Data de início: {{
                        format(parseISO(application.data.starts_at), "'Dia' dd 'de' MMMM', de 'yyyy', às 'HH'h'mm'", {locale: pt})
                      }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Data limite término: {{
                        format(parseISO(application.data.ends_at), "'Dia' dd 'de' MMMM', de 'yyyy', às 'HH'h'mm'", {locale: pt})
                      }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-timer-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="d-flex align-centrer">

                    <p class="ma-0">Duração:
                      {{ application.data.duration === '0' ? 'Sem limite' : `${application.data.duration} min` }}</p>

                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-format-list-numbered</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Marcador: {{ getMarkerType(application.data.marker) }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="ma-0">Aluno
                      {{
                        application.data.has_limited_access ? `pode sair e voltar à avaliação somente ${application.data.access_limit} vezes.` : 'pode sair e voltar à avaliação quantas vezes quiser.'
                      }}</p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-centrer">
                    <p class="d-flex align-center">
                      <span>
                        Turmas:
                      </span>
                      <base-courses :coursesGroup="application.data.courses_group"/>
                    </p>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list-item-group>
            </v-list>
            <v-card-actions>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="36">
                    <img
                      :alt="application.data.author.name"
                      :src="application.data.author.avatar"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </v-avatar>
                </template>
                <span>{{ application.data.author.name }}</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <div class="ml-5">
                <v-card-actions class="d-flex w-100 flex-wrap justify-center">
                  <v-btn
                    :disabled="loadingDialog || loadingQuestions"
                    :loading="loadingDialog"
                    class="ma-1"
                    color="primary"
                    depressed
                    outlined
                    @click="generatePdf('exam')"
                    v-if="application.data.is_offline"
                  >
                    Baixar avaliação
                  </v-btn>
                  <v-btn
                    :disabled="loadingDialog || loadingQuestions"
                    :loading="loadingDialog"
                    class="ma-1"
                    color="primary"
                    depressed
                    outlined
                    @click="generatePdf('bubblesheet')"
                    v-if="application.data.is_offline"
                  >
                    Baixar gabarito
                  </v-btn>
                  <!--<v-btn
                    :to="`/exam/application/edit/${this.$route.params.id}/${application.metadata.id}`"
                    color="primary"
                    outlined
                  >Editar aplicação
                  </v-btn>-->
                  <v-btn
                    :disabled="application.data.status === 'PUBLISHED'
                      || application.data.status === 'ARCHIVED'
                      || !isPreviousDate(application.data.ends_at)"
                    color="primary"
                    depressed
                    @click="sendNotes"
                  >
                    {{
                      application.data.status === 'PUBLISHED'
                        ? 'Notas já devolvidas'
                        : application.data.status === 'ARCHIVED'
                          ? 'Aplicação arquivada'
                          : !isPreviousDate(application.data.ends_at)
                            ? 'Aplicação ainda não encerrada'
                            : 'Devolver notas'
                    }}
                  </v-btn>
                </v-card-actions>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>

        <div class="text-center">

          <v-dialog
            v-model="loadingDialog"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                <strong>Gerando PDF...</strong>
                <v-progress-linear
                  class="mb-0"
                  color="white"
                  indeterminate
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <v-row justify="center">
          <v-dialog
            v-model="pdfDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >

                <v-toolbar-title>Avaliação PDF</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    dark
                    text
                    @click="pdfDialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-list
                subheader
                three-line
              >

                <form-exam-export :examApplication="application" :examData="examData"
                                  :organizationData="organizationData"
                                  :questionsData="questionsData"></form-exam-export>

              </v-list>
            </v-card>
          </v-dialog>
        </v-row>
        <v-col class="mt-3" cols="12" md="6">
          <card-list-dashboard
            :fetch="studentsFetch"
            :footerUrl="show_students ? `/exam/application/students/${this.$route.params.id}/${this.$route.params.application}` : ''"
            :items="students"
            footerText="Visualizar todos os alunos"
            heading="Alunos nessa aplicação"
          ></card-list-dashboard>
        </v-col>
      </v-row>
      <dialog-confirm :dialog="dialog" @action="dialog.show = false" @close="dialog.show = false">
        Esta funcionalidade encontra-se em manutenção e em breve será restabelecida. Tente acessar novamente mais tarde.
      </dialog-confirm>
    </template>
  </v-container>
</template>
<script>
import { mapMutations } from 'vuex'
import { formatStudentsFromMeiliSearch } from '@/utils/student'
import {
  isSuperAdmin,
  getSchoolIds,
  getCourseIds,
  hasOrganizationLevel,
  striphtml
} from '@/utils/helpers'
import { getMarkerType } from '@/utils/marker'
import { compareAsc, format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { getStatusName, getStatusColor } from '@/utils/status'
import fileSaver from 'file-saver'
import collect from 'collect.js'
import organizationType from '@/enums/organizationType'
import EditoraLt from '@/utils/editoraLt'

export default {
  data: () => ({
    dialog: {
      show: false,
      title: 'Gabarito',
      confirm: 'Confirmar',
      close: 'Cancelar'
    },
    pdfDialog: false,
    loadingDialog: false,
    pt,
    format,
    parseISO,
    id: '',
    loadingQuestions: false,
    show: false,
    applicationFetch: false,
    report_url: '',
    organizationData: {},
    examData: {},
    questionsData: {},
    isFinished: false,
    application: {
      data: {
        name: '',
        status: '',
        instructions: '',
        courses_group: [],
        questions_random_order: false,
        wordings_random_order: false,
        answers_random_order: false
      }
    },
    importDialog: false,
    attrs: {
      class: 'mb-6',
      boilerplate: true
    },
    students: [],
    studentsFetch: false,
    pagination: {
      current_page: 1,
      per_page: 10,
      prev_page_url: false,
      next_page_url: false,
      total: 10
    },
    currentPage: 1,
    fetchCount: false,
    show_students: true,
    schools_id: [],
    questions: []
  }),
  computed: {
    getDomains () {
      return this.$store.getters['organization/activeOrganizationDomains'] || []
    }
  },
  methods: {
    getCourseIds,
    getSchoolIds,
    isSuperAdmin,
    hasOrganizationLevel,
    ...mapMutations(['setLoading']),
    saveFile (data, fileName = 'file') {
      if (!data) {
        return
      }
      fileSaver.saveAs(data, `${fileName}.pdf`)
    },
    async getAllQuestions () {
      this.loadingQuestions = true
      const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
      let nexPageUrl = null
      let page = 1
      do {
        const response = await this.$axios.get(`organizations/${organizationId}/exams/${this.$route.params.id}/questions?with_wordings=1&page=${page}`)
        this.questions.push(...response.data.items)
        nexPageUrl = response.data.pagination.next_page_url
        page++
      } while (nexPageUrl)
      this.loadingQuestions = false
    },
    removeUnnecessaryQuestionData (data) {
      const clearedQuestions = data.map((q) => {
        const questions = {
          main_text: q.data.main_text,
          type: q.data.wordings[0].data.type,
          wordings: collect(q.data.wordings[0].data.options).map((w) => {
            return {
              answer: w.answer
            }
          }).values().all()
        }
        return questions
      })
      return clearedQuestions
    },
    async generatePdf (typeFile = 'exam') {
      const lt = new EditoraLt()
      if (typeFile === 'bubblesheet' && lt.activeOrganizationHasDomain(this.getDomains)) {
        this.loadingDialog = true
        lt.downloadPdf(() => {
          this.loadingDialog = false
        })
        return
      }

      this.loadingDialog = true
      const examName = this.application?.data?.exam?.name || this.application?.data?.name
      const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
      const isPruvoOrg = organizationId === '955ac838-cda7-4cfe-b55b-9a0be4cd548b'
      const isCustomLogo = !!organizationType[organizationId]
      const clearedQuestions = this.removeUnnecessaryQuestionData(this.questions)

      const body = {
        data: {
          instructions: striphtml(this.application.data.instructions),
          organization_id: isPruvoOrg ? null : organizationId,
          show_right_logo: isCustomLogo,
          exam: {
            name: examName
          },
          questions: clearedQuestions,
          ...(typeFile === 'bubblesheet' && { quantity: 4 })
        },
        typeFile: typeFile
      }

      this.$axios.post('https://southamerica-east1-pruvo-production.cloudfunctions.net/generatePdf', body, { responseType: 'blob' })
        .then(response => this.saveFile(response.data, examName))
        .catch((error) => this.showError(error))
        .finally(() => (this.loadingDialog = false))
    },
    getMarkerType,
    getStatusColor,
    getStatusName,
    isPreviousDate (date) {
      return compareAsc(new Date(Date.now()), parseISO(date)) !== -1
    },
    examIsFinished () {
      const now = new Date()
      const finalDate = new Date(this.application.data.ends_at)
      this.isFinished = finalDate.getTime() < now.getTime()
    },
    getStudents () {
      const payload = {
        ...(this.getCourseIds().length ? { course_ids: this.getCourseIds() } : {}),
        ...(this.getSchoolIds().length ? { school_ids: this.getSchoolIds() } : {})
      }
      this.$axios.post(
        `/organizations/${this.id}` +
        `/lists/exams-applications/${this.$route.params.application}` +
        '/students?page=1', !this.hasOrganizationLevel() && !this.isSuperAdmin() ? payload : {})
        .then(response => {
          this.students = this.formatStudentsFromMeiliSearch(response.data.items)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.studentsFetch = true
        })
    },
    getApplication () {
      this.$axios.get(
        `organizations/${this.id}` +
        `/exams/${this.$route.params.id}` +
        `/applications/${this.$route.params.application}`
      ).then(res => {
        this.application = res.data
        this.getStudents()
      }).catch((error) => {
        this.showError(error)
      }).finally(() => {
        this.applicationFetch = true
        this.examIsFinished()
      })
    },
    formatStudentsFromMeiliSearch,
    sendNotes () {
      this.$axios
        .patch(
          `/organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}`,
          {
            status: 'PUBLISHED',
            type: 'COURSE'
          }
        )
        .then(() => {
          this.$store.dispatch('alert', { color: 'success', msg: 'Notas enviadas com sucesso' })
          this.application.data.status = 'PUBLISHED'
        }).catch(() => {
          this.showError()
        }).finally(() => {
          this.studentsFetch = true
        })
    },
    showError (error) {
      const defaultMsg = 'Erro na conexão, tente novamente'
      switch (error.response.data.code) {
        case 'EXAMS_APPLICATIONS_GET': {
          this.$store.dispatch('alert', { color: 'red', msg: 'Você não tem acesso a essa aplicação' })
          break
        }
        case 'EXAMS_APPLICATIONS_STUDENTS_LIST': {
          this.show_students = false
          this.$store.dispatch('alert', { color: 'red', msg: 'Você não tem acesso para visualizar os alunos dessa aplicação' })
          break
        }
        default: {
          this.$store.dispatch('alert', { color: 'red', msg: defaultMsg })
        }
      }
    }
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.report_url = `/reports/applications/view/${this.$route.params.id}/${this.$route.params.application}`
    if (this.id) {
      this.getApplication()
      await this.getAllQuestions()
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
  }
}
</script>

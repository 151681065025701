import STATUS from '@/enums/status'
import collect from 'collect.js'

function getStatusName (status = null) {
  const item = collect(STATUS)
    .where('code', status)
    .first()
  return item.value
}

function getStatusColor (status = null) {
  const item = collect(STATUS)
    .where('code', status)
    .first()
  return item.color
}

export { getStatusName, getStatusColor }

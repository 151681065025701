function formatStudentsFromMeiliSearch (students) {
  const arr = []
  if (!students.length) return arr
  students.forEach((value) => {
    const obj = {
      ...value,
      data: {
        ...value,
        user: {
          name: value.student_name,
          avatar: value.student_avatar,
          id: value.student_id
        }
      }
    }
    arr.push(obj)
  })
  return arr
}

export { formatStudentsFromMeiliSearch }

import fileSaver from 'file-saver'
import api from '@/libs/axios'

export default class EditoraLt {
    domain = 'editoralt.com.br'
    pdfServiceUrl = process.env.VUE_APP_EDITORA_LT_PDF

    activeOrganizationHasDomain (domains = []) {
      if (!domains.length) return false
      return domains.some((domain) => domain === this.domain)
    }

    async downloadPdf (callback = () => {}) {
      if (!this.pdfServiceUrl) throw new Error('PDF service url not found')
      api
        .get(this.pdfServiceUrl, { responseType: 'blob' })
        .then((response) => fileSaver.saveAs(response.data, 'editora-lt.pdf'))
        .finally(() => callback())
    }
}
